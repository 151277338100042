import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEineWohnungInNiederoesterreich = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet eine Wohnung in Niederösterreich?" showCalc={false} />
            <Article>
                <p>
                    Du bist auf der Suche nach einer Eigentumswohnung, vielleicht sogar deiner ersten? In diesem Fall
                    ist vor allem eines wichtig: dir möglichst früh einen Überblick über das bundesländerspezifische
                    Angebot zu verschaffen und dadurch ein Gespür für regionale Unterschiede auf dem Markt zu
                    entwickeln. In den Artikeln dieser Serie widmen wir uns daher dem Immobilienmärkten der einzelnen
                    Bundesländer und geben dir einen ersten Überblick über das dortige Angebot an Eigentumswohnungen.
                    Diesmal an der Reihe: Niederösterreich.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Immobilienmarkt in Österreich: aktuelle Entwicklungen</h2>
                <p>
                    Um die aktuellen Entwicklungen auf dem Niederösterreichischen Markt einordnen zu können, müssen wir
                    sie im Kontext des gesamten österreichischen Marktes betrachten. Auf diesem waren 2021 folgende
                    Tendenzen zu beobachten, die auf seit längerem besonders günstige Fixzinsbindung bei Krediten
                    zurückzuführen sind:
                </p>
                <ul>
                    <li>
                        Der Anstieg der Immobilienpreise lag im zweiten Quartal nach wie vor im zweistelligen
                        Prozentbereich und betrug 11,7 %.
                    </li>
                    <li>
                        Der Preisanstieg bei Einfamilienhäusern außerhalb Wiens nahm gegenüber dem ersten Quartal (12,
                        9%) ab und betrug 11,3 %.
                    </li>
                    <li>
                        Der Preisanstieg ist außerhalb Wiens höher als innerhalb: In der Bundeshauptstadt betrug er 2021
                        im ersten Quartal 10,9 % und im zweiten 10,7 %. In den übrigen Bundesländern war ein Anstieg von
                        14,8 % bzw. 12,8 % zu beobachten.
                    </li>
                    <li>
                        Umgelegt auf den Markt an neuen Eigentumswohnungen: Hier stiegen die in Wien 2021 um 10,4 % im
                        ersten und um 10,1 % im zweiten Quartal. Außerhalb der Hauptstadt kam es dagegen hingegen zu
                        einer Teuerung von 16,7 % bzw. 12,9 %.
                    </li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Markt in Niederösterreich</h2>
                <p>
                    Dass auch innerhalb eines Bundeslandes starke regionale Preisunterschiede auftreten können, zeigt
                    der Blick auf die aktuellen Entwicklungen in Niederösterreich. In beliebten Städten steigt der Preis
                    für Wohnraum: So wurden etwa Einfamilienhäuser in Krems zuletzt um 5,4% teurer, in Wiener Neustadt
                    nahmen die Kosten für Wohnraum um 9,66 % zu und in Gmünd legte der Preis für Baugrund sogar um 20 %
                    zu. In strukturschwachen Regionen sinken die Preise dagegen, sodass Immobilien gerade im ländlichen
                    Gebiet besonders günstig zu haben sind. Ebenfalls gut zu wissen: Weil die Mietrenditen teilweise bei
                    über 8 % liegen, ist Niederösterreich auch bei Mietwohnungen eine günstige Alternative zu anderen
                    Bundesländern.
                </p>
                <hr />

                <h2>Das kosten Wohnungen in Niederösterreich</h2>
                <p>
                    Beginnen wir in der Landeshauptstadt: Hier zahlst du beim Kauf einer Wohnung durchschnittlich 2
                    242,75 € pro Quadratmeter Wohnfläche. Das ist dir zu teuer? Dann werfen wir einen Blick nach
                    Korneuburg: Deine neue Wohnung ist in diesem Fall unweit von Wien und kostet dich mit einem
                    durchschnittlichen Quadratmeterpreis von 2 240,11 € etwas weniger als in St. Pölten. Schon deutlich
                    günstiger wohnen kannst du in Hollabrunn, wo du aktuell mit ca. 2 036,28 € für einen Quadratmeter
                    rechnen musst. Es geht aber noch günstiger: 1 674,50 € pro Quadratmeter kostet eine Eigentumswohnung
                    im urbanen Gebiet von Wiener Neustadt. Waidhofen an der Thaya kann das sogar noch toppen: Hier
                    bezahlst du für eine Quadratmeter Wohnfläche 1 297,67 €.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEineWohnungInNiederoesterreich"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wasKostetEineWohnungInNiederoesterreich"}
                heading={"Was kostet eine Wohnung in Niederösterreich?"}
            />
        </Layout>
    );
};

export default WasKostetEineWohnungInNiederoesterreich;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "header"
                        "footer"
                        "page.artikel.was-kostet-eine-wohnung-in-niederoesterreich"
                        "mortgageCalc"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
